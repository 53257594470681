@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.nfd-gradient-bg {
    background: linear-gradient(270deg, #1e3a8a, #9333ea, #f59e0b, #10b981) !important;
    background-size: 800% 800%;
    animation: gradient 15s ease infinite;
}