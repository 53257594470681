/* add css module styles here (optional) */

.softwareasaservicedashboard {
  font-family: 'Outfit', sans-serif;
  height:100vh;width:100%;margin:0;padding:0;left:0;top:0;font-size:100%;
  box-shadow: none;
}

.softwareasaservicedashboard .flex{
  display: flex;
  height: 100vh;
}

.softwareasaservicedashboard .outlet{
  box-shadow: none;
}
.softwareasaservicedashboard .header{

}

.softwareasaservicedashboard .profileicon{
  width:22px;height:22px;margin-top:2px;border-radius: 50%;
}

.softwareasaservicedashboardTable {
	display: grid;
	grid-template-columns: [col1] 33% [col2] 33% [col3] 33%;
	grid-gap: 10px;
	grid-template-rows: [row1] 47% [row2] 47%;
	text-align: center;
}

.softwareasaservicedashboardFlex {
  width: 99vw;
  height: 99vh;
  display: flex;
  justify-content: center;
}

.softwareasaservicedashboard .page{
  width: 70vw;margin:10px auto;
}

/* latin-ext */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1O4i0EwItq6fNIhnL.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1O4a0EwItq6fNIg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}